import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import User from './User'
import Cart from './Cart'



Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      User,
      Cart
    },
    strict: process.env.NODE_ENV !== 'production',
    plugins: [new VuexPersistence().plugin]

})