<template>
<div>
 <nav v-if="page != 'login' && page != 'signup' && page != 'forgetPassword'">
    <v-app-bar
      color="#E7F6FF"
      dark
      flat
      fixed
    >
       <v-app-bar-nav-icon light class="AppMenu1" @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title @click="navigate('home')"  class="ml-5 logo mt-5">
          <img src="../assets/icon.png" alt="mvs logo" width="100px" height="50px">
      </v-toolbar-title>
      <v-spacer></v-spacer>  
      <v-text-field
        v-show="page != 'StoreProfile'"
        flat
        rounded
        autofocus
        class="mx-4"
        hide-details
        label="Search"
        prepend-inner-icon="mdi-magnify"
        solo-inverted
        dark
        color='black'
        v-model="searchText"
        background-color='#c9ebff'
      ></v-text-field>
    <v-spacer></v-spacer>
      <v-btn
          v-if="User"
            @click="navigate('dashboard')"
          text
          light
          class="btnn"
        >
            <v-icon light>mdi-account</v-icon>
          Profile
        </v-btn>
          <v-btn
          v-else
          @click="navigate('login')"
            class="ma-2 btnn"
            text
            light
            
          >
        Login To account
        </v-btn>
          <v-btn
           @click="drawerCart = !drawerCart"
          text
            light
          class="mr-5 cartBody"
        >
          <v-badge
          v-if="Carts.length > 0"
          color="red"
          :content="Carts.length"
        >
          <v-icon>mdi-cart</v-icon>
          </v-badge>
          <v-icon v-else>mdi-cart</v-icon>
         <span class="btnn"> Cart</span>
        </v-btn>
      <div v-if="Dataitems.length > 0" class="searchDate">
        <div class="clearbtn">
              <v-spacer></v-spacer>
          <v-btn @click="clearsearch()" text color='info' small>Clear All</v-btn>
        </div>
      <v-divider light></v-divider>
      <v-list-item v-for="n in Dataitems" :key="n._id"  @click="storeNavigate(n._source.storeId)" class="stateDate">
        <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
            mdi-folder
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="black--text">{{n._source.storeName}}</v-list-item-title>
          <v-list-item-subtitle class="black--text">{{n._source.name}} || {{DisplayCurrency(n._source.SellingPrice)}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-store</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      </div>
    </v-app-bar>
     <v-app-bar
      color="#1c63ba"
      dark
      flat
      class="mt-15"
      v-if="page != 'StoreProfile'"
    >
       <v-menu  v-if="page == 'home'">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-3"
              dark
              text
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
        <span class="line">
             Choose Closest Location
        </span>
        <span class="ml-2">>
        </span>
        <small class="location">{{stateDate.name}}</small>
        </v-btn>
            
          </template>
          <span> Choose Closest Location</span>
        </v-tooltip>
      </template>
      <v-list class="stateList">
        <v-list-item
          v-for="(item, index) in stateD"
          :key="index"
        >
          <v-list-item-title @click="getStateDate(item)" class="stateDate">{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
     <v-toolbar-title v-if="page == 'StoreProfile'">
        <span>
            Store Name
        </span>
    </v-toolbar-title>
    <v-menu  v-if="page == 'home'">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-3"
              dark
              text
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
            See all categories
            </v-btn>
          </template>
          <span>Store Categories</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
   
        <v-spacer></v-spacer>
        <v-btn
            class="ma-2 btnn"
            text
            small
            @click="navigateToInventory()"
          >
        Login To Inventory
        </v-btn>
    </v-app-bar>
 </nav>
 <v-navigation-drawer
      fixed
      v-model="drawer"
      temporary
    >
    <SideMenuList/>
    </v-navigation-drawer>
     <v-navigation-drawer
     right
      fixed
      width="480"
      v-model="drawerCart"
      temporary
    >
       <v-btn
        @click="drawerCart = !drawerCart"
        fab
        x-small
        dark
        class="ml-5"
      >
      <v-icon>mdi-close</v-icon>
      </v-btn>
    <div class="text-center pa-10" v-if="Carts.length == 0">
    <p>NO ITEM</p>
    </div>
    <CartDetails/>
    <div class="text-center mb-10">
    <v-btn
    v-if="Carts.length > 0"
    @click="navigate('Checkout')"
      rounded
      color="primary"
      dark
      class="ma-5"
    >
      Check Out
    </v-btn>
    </div>
    </v-navigation-drawer>
</div>
</template>

<script>
import currencyFormatter from 'currency-formatter'
import {GeneralSearch, DomainImage} from '../APIs'
import axios from "axios";
import {getCitiesByCountryCode} from "country-city-location";
import SideMenuList from '../components/SideMenuList'
import CartDetails from '../components/CartDetails'

export default {
  created(){
    this.DomainImage = DomainImage
  },
      components:{
        SideMenuList,
        CartDetails
      },

      data: () => ({
      drawer: false,
      drawerCart: false,
      cartopen:null,
      group: null,
      items: [],
      text:"",
      searchText:"",
      states:getCitiesByCountryCode("NG"),
      stateD:getCitiesByCountryCode("NG"),
      Dataitems:[],
      DomainImage:'',
      cartBodyData:false,
      SearchData:[]
      }),
    
    methods:{
      clearsearch(){
        this.SearchData = []
        this.Dataitems = []
      },
     navigateToInventory(){
      let url = 'https://business.mvs.ng'
      window.open(url, '_blank');
    },
         SearchProduct(){
          this.$store.commit("setLoading", true)
          axios.put(`${GeneralSearch}?lat=${this.stateDate.lat}&lon=${this.stateDate.lng}`,{
            search:this.searchText
          })
          .then(res => {
              this.Dataitems= res.data.Items
              if(res.data.Items.length == 0){
              this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "no records")
              }
          })
          .catch(err => {
            console.log(err.data, "GetStores: ERR")
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
      },
      getStateDate(payload){
        this.states = payload
        this.$store.commit("setLocation", payload)
        let payload2= {
        lat: payload.lat,
        lng: payload.lng,
      }
      this.$store.dispatch('GetNearStore', payload2)
      },
      storeNavigate(payload){
         this.Dataitems = []
        this.$router.push({path:`/Store/${payload}`});
      },
      navigate(payload){
           if(payload == 'Checkout' && this.Carts.length == 0){
             return
           }
           this.$router.push({name:payload});
      },
      addToCart(payload){
     this.$store.commit("addItem", payload)
   },
   removeItem(payload){
      this.$store.commit("removeItem", payload)
   },
   reduceItem(payload){
      this.$store.commit("reduceItem", payload)
   },
    DisplayCurrency(payload, payload2) {
    return currencyFormatter.format(payload, {
        code: payload2
    });
    },
    },
    computed:{
      stateDate(){
         return this.$store.getters.stateDate;
      },
    page(){
       return this.$route.name
      },
     pages(){
       return this.$route.params.id
      },
    User() {
      return this.$store.getters.User;
  },
  Carts() {
      return this.$store.getters.totalItem;
  },
    },
   watch: {
      searchText (val) {
        if(val.length > 2){
          this.SearchProduct()
        }
    },
      text (val) {
        if(val.length > 2){
          this.SearchStoreProduct()
        }
    }
  },
    

}
</script>

<style scoped>
.location{
    color: #eed72b;
}
.line{
    text-decoration-line: underline;
    font-size: 12px;
}
.cartImg {
  border-radius: 10px;
  height: 100px;
  overflow: hidden;
}
.cart{
  background-color: #c9ebff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px !important;
}
.qty{
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1156aa;
}
.qtyform{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cover{
  padding: 20px;
  overflow: scroll;
  padding-bottom: 100vh;
}
.description{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
  height: 60px;
  overflow: hidden;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}
.amount{
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.logo{
    cursor: pointer;
}
.colorr{
    background-color: #1c63ba;
}
.stateList{
  height: 50vh;
  overflow: scroll;
}
.stateDate{
  cursor: pointer;
}

.storeSearch{
 position: relative; 
}
.storeSearchData{
   position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  right: 35%;
  z-index:100;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
.searchDate{
  position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  z-index:100;
  right: 37%;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
.clearbtn{
  display: flex;
}
.cover::-webkit-scrollbar {
  width: 0.4em !important;
  
}
.cover::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
.cartBodyData{
  position: absolute;
  width: 460px;
  max-height: 100vh;
  overflow: scroll;
  background-color: white;
  top:0px;
  right: 0px;
  padding-bottom: 10px;

}
.cartBody{
  position: relative;
}
.cartBodyData::-webkit-scrollbar {
  width: 0.4em !important;
  
}
 .AppMenu1{
    display:none;
  }
.cartBodyData::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
@media only screen and (max-width: 700px) {
  .logo{
    display: none;
  }
  .btnn{
    display: none;
  }
  .AppMenu1{
    display:flex;
  }
  .AppMenu2{
    display: none;
  }
  .searchDate{
  position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  z-index:100;
  right: 0px;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
}
</style>