<template>
     <div>
         <div class="card" :key='i._id' v-for="i in Carts">
             <div class="card1">
               <v-img height="95" width="95"
                 lazy-src="https://picsum.photos/id/11/10/6"
                  :src="i.Imageurl ? DomainImage+i.Imageurl :'https://picsum.photos/id/11/10/6'"
                  >
                </v-img>  
             </div>
             <div class="card2">
             <p class="amount mt-2">{{i.name}}</p>
              <p class="description line-clamp">
               {{i.description}}
              </p>
              <p class="amount">{{ DisplayCurrency(i.SellingPrice * i.quantity, i.storeId.currency)}}</p>
             </div>
             <div class="card3">
                 <p class="qty">QUANTITY</p>
              <div class="qtyform">
              <v-btn
              @click="reduceItem(i)"
              class="mx-2"
              fab
              dark
               x-small
              color="#1156aa"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
              </v-btn>
              <v-card light class="pa-2 pl-4 pr-4">
                {{i.quantity}}
              </v-card>
            <v-btn
            @click="addToCart(i)"
              class="mx-2"
              fab
              dark
             x-small
              color="#1156aa"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              </v-btn>
              </div>
               <div class="text-center">
               <v-btn class="mt-3" x-small light @click="removeItem(i)" text>
                <v-icon color='red' left>
                  mdi-delete
                </v-icon>
                <small class="mt-2">Delete</small>
              </v-btn>
               </div>
             </div>
         </div>
    </div>
</template>

<script>
import { DomainImage} from '../APIs'
import currencyFormatter from 'currency-formatter'
export default {
    props:['navigated'],
      created(){
    this.DomainImage = DomainImage
  },
      data: () => ({
      drawer: false,
      cartopen:null,
      group: null,
      items: [],
      text:"",
      searchText:"",
      Dataitems:[],
      DomainImage:'',
      SearchData:[]
      }),
    
    methods:{
      clearsearch(){
        this.SearchData = []
        this.Dataitems = []
      },
      getStateDate(payload){
        this.states = payload
        this.$store.commit("setLocation", payload)
        let payload2= {
        lat: payload.lat,
        lng: payload.lng,
      }
      this.$store.dispatch('GetNearStore', payload2)
      },
      storeNavigate(payload){
         this.Dataitems = []
        this.$router.push({path:`/Store/${payload}`});
      },
      navigate(payload){
           this.$router.push({name:payload});
      },
      addToCart(payload){
     this.$store.commit("addItem", payload)
   },
   removeItem(payload){
      this.$store.commit("removeItem", payload)
   },
   reduceItem(payload){
      this.$store.commit("reduceItem", payload)
   },
    DisplayCurrency(payload, payload2) {
    return currencyFormatter.format(payload, {
        code: payload2
    });
    },
    },
        computed:{
      stateDate(){
         return this.$store.getters.stateDate;
      },
    page(){
       return this.$route.name
      },
     pages(){
       return this.$route.params.id
      },
    User() {
      return this.$store.getters.User;
  },
  Carts() {
      return this.$store.getters.totalItem;
  },
    },
}
</script>

<style scoped>
.line{
    text-decoration-line: underline;
}
.cartImg {
  border-radius: 10px;
  height: 100px;
  overflow: hidden;
}
.cart{
  background-color: #c9ebff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px !important;
}
.qty{
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1156aa;
}
.qtyform{
  display: flex;
  justify-content: center;
  align-items: center;
}
.description{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
  max-height: 40px;
  overflow: hidden;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}
.amount{
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.logo{
    cursor: pointer;
}
.colorr{
    background-color: #1c63ba;
}
.stateList{
  height: 50vh;
  overflow: scroll;
}
.stateDate{
  cursor: pointer;
}

.searchBar{
  position: relative;
}
.storeSearch{
 position: relative; 
}
.storeSearchData{
   position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  right: 35%;
  z-index:100;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
.searchDate{
  position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  z-index:100;
  right: 37%;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
.clearbtn{
  display: flex;
}
.cover::-webkit-scrollbar {
  width: 0.4em !important;
  
}
.cover::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
.cartBodyData{
  position: absolute;
  width: 500px;
  max-height: 100px;
  background-color: white;
  top:50px;
  right: 0px;
  border-top-left-radius: 20px;

}
.cartBody{
  position: relative;
}
.card{
    display: flex;
    background-color: #c9ebff;
    height: 120px;
   align-items: center;
   border-radius: 10px;
   padding:10px;
   margin: 10px;
}
.card1{
  flex: 1;
}
.card2{
flex: 2;
margin: 10px;
}
.card3{
 flex: 1;
}

</style>