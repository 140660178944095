

export const Domain = "https://suntrust.milesbreed.com/api/v1";
export const DomainImage = "https://suntrust.milesbreed.com/api";
export const DomainSocket = "suntrust.milesbreed.com";

//export const Domain = "http://localhost:3021/api/v1";
//export const DomainImage = "http://localhost:3020/api/";
//export const DomainSocket = "http://localhost:3021";

export const Login = `${Domain}/auth/Login`;
export const SignUp = `${Domain}/auth/SignUp`;
export const GoogleAuth = `${Domain}/auth/GoogleAuth`;
export const UpdateUserProfile = `${Domain}/auth/UpdateUserProfile`;
export const changepassword = `${Domain}/auth/changepassword`;
export const RestPasswordByEmail = `${Domain}/auth/RestPasswordByEmail`;
export const ConfirmPasswordBySentToEmail = `${Domain}/auth/ConfirmPasswordBySentToEmail`;
export const GetOrder = `${Domain}/order/GetOrder`;
export const GetNearByStore = `${Domain}/store/GetNearByStore`;
export const GetStoreProducts = `${Domain}/store/GetStoreProducts`;
export const PlaceOrder = `${Domain}/order/PlaceOrder`;
export const payForOrder = `${Domain}/order/PayForOrder`;
export const GeneralSearch = `${Domain}/product/SearchNearProduct`;
export const SearchStoreProduct = `${Domain}/product/SearchStoreProduct`;

