<template>
  <v-app>
 <Header/>
 <Loading v-if="Loading" />
    <v-main>
      <router-view></router-view>
    </v-main>
  <Snackbar/>
  <Footer/>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Loading from './components/Loading'
import Snackbar from './components/Snackbar'

export default {
  name: 'App',
    components:{
       Header,
       Footer,
       Loading,
       Snackbar
    },
    computed:{
        Loading() {
          return this.$store.getters.Loading;
        },

  },


  data: () => ({
    //
  }),
};
</script>
<style>
@font-face {
font-family:'Poppins-Black';
src: url('~@/assets/font/Poppins-Black.ttf');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family:'Poppins-BlackItalic';
src: url('~@/assets/font/Poppins-BlackItalic.ttf');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family:'Poppins-Bold';
src: url('~@/assets/font/Poppins-Bold.ttf');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family:'Poppins-BoldItalic';
src: url('~@/assets/font/Poppins-BoldItalic.ttf');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family:'Poppins-ExtraBold';
src: url('~@/assets/font/Poppins-ExtraBold.ttf');
font-weight: normal;
font-style: normal;
}
@font-face {
font-family:'Poppins-ExtraBoldItalic';
src: url('~@/assets/font/Poppins-ExtraBoldItalic.ttf');
font-weight: normal;
font-style: normal;
}
body::-webkit-scrollbar {
  width: 0.4em;
  
}
body::-webkit-scrollbar-thumb {
  background-color: #1c63ba;
  border-radius: 10px;
}

</style>
