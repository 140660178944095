import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({

    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        }else{
            return{
                x:0,
                y:0
            }
        }
    },
    routes:[
        {
            path:"/",
            name:"home",
            component: ()=>import("../pages/LandingPage.vue")
        },
        {
            path:"/Login",
            name:"login",
            meta: { forVisitors: true },
            component: ()=>import("../pages/Login.vue")
        },
        {
            path:"/Signup",
            name:"signup",
            meta: { forVisitors: true },
            component: ()=>import("../pages/Signup.vue")
        },
        {
            path:"/ForgetPassword",
            name:"forgetPassword",
            meta: { forVisitors: true },
            component: ()=>import("../pages/ForgetPassword.vue")
        },
        {
            path:"/dashboard",
            name:"dashboard",
            meta: {forAuth: true},
            component: ()=>import("../pages/Profile.vue"),
            children:[
               {
                path:"/dashboard/ProfileDashborad",
                name:"profileDashborad",
                component: ()=>import("../pages/ProfileDashborad.vue"),
               } ,
               {
                path:"/dashboard/OrderList",
                name:"OrderList",
                component: ()=>import("../pages/OrderList.vue"),
               },
               {
                path:"/dashboard/Notification",
                name:"notification",
                component: ()=>import("../pages/Notifications.vue"),
               } 
            ]
        },
        {
            path:"/Store/:id",
            name:"StoreProfile",
            component: ()=>import("../pages/StoreProfile.vue")
        },
        {
            path:"/Checkout",
            name:"Checkout",
            component: ()=>import("../pages/Checkout.vue")
        },
        {
            path:"*",
            name:"page404",
            component: ()=>import("../pages/Page404")
        },
    ]

})