import Vue from 'vue'
import App from './App.vue'
import router from './Router/'
import store from './store/'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import Auth from '../src/middleware/'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import GAuth from 'vue-google-oauth2'
const GOOGLE_MAPS_APIKEY = "AIzaSyAH_Pt2We-VEQunHe6lXfzydv3gabyWgU4";

const gauthOption = {
  clientId: '21260641709-sc3tphv5bu7cr0c22rfvgs6md4l45bga.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: GOOGLE_MAPS_APIKEY, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  version: '...', // Optional
  language: '...', // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

Vue.config.productionTip = false

Vue.use(Auth);
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.forVisitors)) {
    if (store.getters.getUser) {
      next({
        path: '/'
      })
    } else next()
  }
  else if (to.matched.some(record => record.meta.forAuth)) {
    if (!store.getters.getUser) {
      next({
        path: '/login', query: { redirect: to.path }
      })
    } else next()
  }
  else next()

})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
