import {payForOrder,GetNearByStore} from '../APIs'

import axios from "axios";

const state = {
  totalItem:[],
  Store:"",
  totalItemsStores:0,
  stateDate:{
    name:"Abuja",
    lat:"9.0833",
    lng:"7.5333"
  }
}
const getters = {
    totalItemsStores(state){
        return state.totalItemsStores
    },
    stateDate(state){
        return state.stateDate
    },
    Store(state){
        return state.Store
    },
    totalItem(state){
        return state.totalItem
    },
}

const mutations = {
    ClearCart(state, payload){
        state.Store = ""
        state.totalItem = payload;
    },
    setStore(state, payload){
        state.Store = payload;
    },
  addItem(state, payload){

    state.Store = payload.storeId
    let newDate = state.totalItem
    let dontAdd = false
    if(state.totalItem.length > 0){
    newDate.forEach(element => {
        if(element._id == payload._id){  
           // element.quantity = parseInt(element.quantity) + 1  
            console.log("Same", element.quantity)
            dontAdd =true
            payload.quantity = element.quantity + 1
        }   
    });
    if(dontAdd){    
        //state.totalItem = newDate;
        let total = state.totalItem.filter(item =>JSON.stringify(item._id) != JSON.stringify(payload._id))
        total.push(payload)
        state.totalItem = total
        return
    }else{
        newDate.push(payload)  
        state.totalItem = newDate;  
        return
    }

  }
  newDate.push(payload)  
  state.totalItem = newDate;
  console.log(state.totalItem, "nodata", newDate)
  }, 

  removeItem(state, payload){
      console.log("removeItem")
    let total = state.totalItem.filter(item =>JSON.stringify(item._id) != JSON.stringify(payload._id))
    state.totalItem = total;
    if(total.length == 0){
        state.Store = ''
    }
},

reduceItem(state, payload){
  let newDate =[]
  state.totalItem.forEach(element => {
      if(element._id == payload._id){
          if(element.quantity > 1){
              let opt={
                  ...payload,
                  quantity: element.quantity - 1
              }
              newDate.push(opt)
          }
      }else{
          newDate.push(element)   
      }
  });
  state.totalItem = newDate;
  if(newDate.length == 0){
      state.Store = ''
  }
},

settotalItem(state, payload){
    state.totalItem = payload; 
},
setLocation(state, payload){
    state.stateDate = payload
},
settotalItemsStores(state, payload){
    state.totalItemsStores = payload
}

}

const actions = {
    
    GetNearStore({commit},payload){
        
        commit("setLoading", true) 
           axios.get(`${GetNearByStore}?lat=${payload.lat}&lon=${payload.lng}`)
             .then(res => {
                commit("setStores", res.data.Store)
                commit("settotalItemsStores", res.data.totalItems)
             })
             .catch(err => {
               console.log(err.data, "GetStores: ERR")
             })
             .finally(()=>{
                commit("setLoading", false)
             })
    },
    VerifyPay({commit}, payload){
        commit("setLoading", true);
        commit("setSnackbar", false);
        axios.put(`${payForOrder}`,{
            orderId: payload.orderId,
            reference:payload.reference
            },
            {
               headers: { Authorization: "Bearer " + payload.Token } 
            })
            .then(res => {
                console.log(res.data, "res.data")
                commit("setSnackbar", true);
                commit("setServerMessage", "Successful")
                commit("ClearCart", [])
                payload.route.replace({path: "/dashboard/OrderList"})    
            })
            .catch(err => {
                console.log(err.response, "o: ERR")
                 commit("setSnackbar", true);
                 commit("setServerMessage", err.response.data.message)
            })
            .finally(()=>{
                commit("setLoading", false);
            })
      },
}



export default {
    state,
    mutations,
    getters,
    actions
  };