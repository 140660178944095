export default function (Vue) {
    let authenticatedUser = {}

    Vue.auth = {
        setToken(token) {
            this.$store.commit('setAuthUser', token)
        },
        getToken() {     
            if (this.$store.User.state.getauthUser) {
                return null
            }   
            return this.$store.User.state.getauthUser
        },

        destoryToken() {
            this.$store.commit('setAuthUser', null)
        },

        isAuthenticated() {
            let valid = this.getToken()
            if (!valid) {
                return false
            }
            else
                return false
        },
        setAuthenticatedUser(data) {
            authenticatedUser = data
        },

        getAuthenticatedUser() {
            return authenticatedUser
        }
    }

    Object.defineProperties(Vue.prototype, {
        $auth: {
            get() {
                return Vue.auth;
            }
        }
    })
}