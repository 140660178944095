<template>
  <v-footer
    dark
    padless
    class="footer"
  >
      <div class="follow footer pa-4">
        <div>
        <small>follow us</small>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-1"
          dark
          icon
        >
          <v-icon size="20px">
            {{ icon }}
          </v-icon>
        </v-btn>
       <p> Our Office Address</p>
      <small> Suit 13, Eastley Park Plaza, 5 Barthurst Street,  Wuse 2 Abuja FCT, Nigeria</small>
        </div>
            <v-spacer></v-spacer>
        <div >
        <v-card-text class="white--text">
        Copyright<strong> MVS</strong> {{ new Date().getFullYear() }}. All Right Reserved
      </v-card-text>
        </div>
      </div>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }),
  }
</script>
<style scoped>
.footer{
    background-color: #1c63ba;
    width: 100%;
}
.follow{
  display: flex;
  justify-content:space-between;
  align-items:flex-end;
}
</style>