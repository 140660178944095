<template>
<div>
    <v-list v-if="!Token">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="navigate(item.path)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
      <v-list v-else>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items2"
          :key="i"
          @click="navigate(item.path)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</div>
</template>
<script>
  export default {
    data: () => ({
      selectedItem: 1,
      items: [
        { text: 'Home', icon: 'mdi-home', path:"home"},
        { text: 'Sign Up', icon: 'mdi-account', path:"signup"},
        { text: 'Login', icon: 'mdi-lock', path:"login"},
        { text: '  Login To Inventory', icon: 'mdi-lock', path:"Inventory"},
      ],
       items2: [
        { text: 'Home', icon: 'mdi-home', path:"home"},
        { text: 'Profile', icon: 'mdi-account', path:"profileDashborad"},
        { text: 'Order History', icon: 'mdi-lock', path:"OrderList"},
      ],
    }),
    methods:{
           navigate(payload){
               if(payload == 'Inventory'){
                    let url = 'https://business.mvs.ng'
                    window.open(url, '_blank');
                   return
               }
           this.$router.push({name:payload});
      },
    },
     computed:{
      Token() {
      return this.$store.getters.Token;
  },
     }
  }
</script>