import {Login, GetOrder} from '../APIs'

import axios from "axios";

const state = {
    Loading: false,
    Snackbar:false,
    User:"",
    Stores:[],
    Token:"",
    Order:[],
    OrdertotalItems:0,
    ServerMessage:"",
    loginTime:""
}
const getters = {
  OrdertotalItems(state){
    return state.OrdertotalItems
  },
  getUser(state){
    return state.User
  },
  Stores(state){
        return state.Stores
    },
    Loading(state){
      return state.Loading
  },
    Order(state){
        return state.Order
    },
    Snackbar(state){
        return state.Snackbar
    },
    User(state){
        return state.User
    },
    Token(state){
        return state.Token
    },
    LoginTime(state){
      return state.loginTime
  },
    ServerMessage(state){
        return state.ServerMessage
    },
}
const mutations = {
    setStores(state, payload){
        state.Stores = payload
    },
    setLoading(state, payload){
      state.Loading = payload
  },
    setOrder(state, payload){
        state.Order = payload
    },
    setSnackbar(state, payload){
        state.Snackbar = payload
    },
    setUser(state, payload){
        state.User = payload
    },
    setSignOut(state, payload){
      state.User = ''
      state.Token = ''
      payload.route.push({name: "login"})
    },
    setToken(state, payload){
        state.loginTime = 8.64e+7 + Date.now()
        state.Token = payload
    },
    setServerMessage(state, payload){
        state.ServerMessage = payload
    },
    setOrdertotalItems(state, payload){
      state.OrdertotalItems = payload
    }   
}
const actions = {

    Login ({commit}, payload){
        commit("setLoading", true);
        commit("setSnackbar", false);
        axios
          .post(`${Login}`,{
            phoneNumber: payload.phoneNumber,
            password: payload.password,
          })
          .then(res => {
               commit("setUser", res.data.profile)
               commit("setToken", res.data.token)
               if(payload.QuckLogin){
                //payload.route.push({name: "home"})
                return
               }
              if (payload.redirect) {
                payload.route.push({path:payload.redirect});
              } else {
              payload.route.push({name: "home"})
              }
               //console.log(res.data, "res.data")
          })
          .catch(err => {
            console.log(err.data, "Login: ERR")
            commit("setSnackbar", true);
            commit("setServerMessage", err.response.data.message)
          })
          .finally(()=>{
            commit("setLoading", false);
          })
      },
    
    
    GetOrders ({commit}, payload){
        commit("setLoading", true);
        commit("setSnackbar", false);
        axios
          .get(`${GetOrder}?page=${payload.page}`,{
            headers: { Authorization: "Bearer " + payload.Token } 
          })
          .then(res => {
            
               commit("setOrder", res.data.order)
               commit("setOrdertotalItems", res.data.totalItems)
               console.log(res.data.message, "res.data")
          })
          .catch(err => {
            console.log(err.data, "Login: ERR")
            commit("setSnackbar", true);
            commit("setServerMessage", err.message)
          })
          .finally(()=>{
            commit("setLoading", false);
          })
      },
}



export default {
    state,
    mutations,
    getters,
    actions
  };